import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "../../Inputs/TextField";
import Select from "../../Inputs/Select";
import Button from "../../Inputs/Button";

export class PostSearch extends Component {
  handleChange = (e) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  handleSelectOrderType = (orderType) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, orderType });
  };

  handleSelectOrderField = (orderField) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, orderField });
  };

  render() {
    const { name, orderType, orderField } = this.props.search;
    const { handleSearch } = this.props;

    const orderTypeOptions = [
      { label: "Ascending", value: "ASC" },
      { label: "Descending", value: "DESC" },
    ];

    const orderFieldOptions = [
      { label: "Name", value: "name" },
      { label: "Start date", value: "startDate" },
      { label: "End date", value: "endDate" },
    ];

    const groupStyles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };
    const groupBadgeStyles = {
      backgroundColor: "#EBECF0",
      borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center",
    };

    const formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );

    return (
      <form className="flex" onSubmit={(e) => handleSearch(e, name)}>
        <TextField
          name="name"
          value={name}
          placeholder="Search"
          className="input-sm"
          onChange={this.handleChange}
          maxLength={30}
        />
        {/* <Select
          value={orderField}
          name="orderField"
          options={orderFieldOptions}
          className="input-sm"
          onChange={this.handleSelectOrderField}
          clearable={false}
          placeholder="Sort by"
        />
        <Select
          value={orderType}
          name="orderType"
          options={orderTypeOptions}
          className="input-sm"
          onChange={this.handleSelectOrderType}
          clearable={false}
        /> */}
        <Button text="Search" type="submit" />
      </form>
    );
  }
}

PostSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
};

export default PostSearch;
