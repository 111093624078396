import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { login } from "../../redux/actions/authActions";
import TextField from "../Inputs/TextField";
import Button from "../Inputs/Button";

export const Login = ({ login, isAuthenticated, loading, errors }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="login-form">
      <form onSubmit={(e) => onSubmit(e)}>
        <TextField
          name="email"
          value={email}
          type="text"
          label="Email Address"
          placeholder="Email address"
          onChange={(e) => onChange(e)}
          error={errors.email}
        />
        <TextField
          name="password"
          value={password}
          type="password"
          label="Password"
          placeholder="Password"
          onChange={(e) => onChange(e)}
          error={errors.password}
        />
        <Button
          text="Sign in"
          type="submit"
          isLoading={loading}
          disabled={false}
          // onClick={e => onSubmit(e)}
        />
      </form>

      <Link style={{ fontSize: "14px" }} to="/password-reset">
        Forgot password?
      </Link>

      <p className="text-danger">{errors.message}</p>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  errors: state.errors,
});

export default connect(mapStateToProps, { login })(Login);
