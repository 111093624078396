import React, { useState, useEffect } from "react";
import TextField from "../../../Inputs/TextField";
import PropTypes from "prop-types";

const TextArea = ({ grabQuestionData, data }) => {
  const [questionData, setQuestionData] = useState({
    maxResponseLength: 100,
  });

  const { maxResponseLength } = questionData;

  useEffect(() => {
    setQuestionData({
      maxResponseLength: data.maxResponseLength,
    });
  }, [data.maxResponseLength]);

  useEffect(() => {
    grabQuestionData({
      maxResponseLength,
    });
  }, [maxResponseLength]);

  return (
    <div>
      <TextField
        label="Response Length"
        type="number"
        value={maxResponseLength}
        name="maxResponseLength"
        format="####"
        className="input-lg"
        onChange={(e) =>
          setQuestionData({
            ...questionData,
            maxResponseLength: e.target.value,
          })
        }
      />
    </div>
  );
};

TextArea.propTypes = {};

export default TextArea;
