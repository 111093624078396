import React from "react";
import PropTypes from "prop-types";
import TextField from "../../Inputs/TextField";
import Button from "../../Inputs/Button";
import Select from "../../Inputs/Select";

const UserSearch = ({ search, handleSearch, handleChange, options }) => {
  const handleSelect = (val) => {
    handleChange({ ...search, role: val });
  };

  const { role, name } = search;

  return (
    <form className="flex" onSubmit={(e) => handleSearch(e)}>
      <TextField
        name="search"
        value={name}
        placeholder="Search"
        onChange={(e) =>
          handleChange({
            ...search,
            name: e.target.value,
          })
        }
        maxLength={30}
      />
      <Select
        value={role}
        name="select"
        options={options}
        className="input-sm"
        placeholder="Role"
        onChange={handleSelect}
      />
      <Button text="Search" type="submit" />
    </form>
  );
};

export default UserSearch;
UserSearch.propTypes = {
  search: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

UserSearch.defaultProps = {};
