import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "../../../Inputs/TextField";
import css from "./Dropdown.module.scss";
import _ from "lodash";
import { isEmpty } from "../../../../utils";

const DropdownOption = ({ updateOption, deleteOption, index, opt }) => {
  const [text, setText] = useState({ text: "", text_fr: "" });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setText(opt);
  }, []);

  const handleUpdate = () => {
    if (!isEmpty(text.text) && !isEmpty(text.text_fr)) {
      setErrors({});
      updateOption(index, text);
    } else {
      setErrors({
        text: isEmpty(text.text) ? "This field cannot be empty." : undefined,
        text_fr: isEmpty(text.text_fr)
          ? "This field cannot be empty."
          : undefined,
      });
    }
  };

  return (
    <li onMouseLeave={handleUpdate} onBlur={handleUpdate}>
      <TextField
        label="Text (en)"
        name="text"
        value={text.text}
        onChange={(e) => setText({ ...text, text: e.target.value })}
        maxLength={250}
        error={!isEmpty(errors) ? errors.text : null}
      />
      <TextField
        label="Text (fr)"
        name="text_fr"
        value={text.text_fr}
        onChange={(e) => setText({ ...text, text_fr: e.target.value })}
        maxLength={250}
        error={!isEmpty(errors) ? errors.text_fr : null}
      />
      <span onClick={() => deleteOption(index)}>x</span>
    </li>
  );
};

function Dropdown({ grabQuestionData, data, errors }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const payload = data.map((el) => ({
      ...el,
      id: _.uniqueId("dropdown_opt_"),
    }));
    setOptions(payload);
  }, []);

  useEffect(() => {
    grabQuestionData({
      options: options.map((el) => ({
        text: el.text,
        text_fr: el.text_fr,
      })),
    });
  }, [JSON.stringify(options)]);

  const addOption = () => {
    const arr = options.slice();
    arr.push({ text: "", text_fr: "", id: _.uniqueId("dropdown_opt_") });
    setOptions(arr);
  };

  const updateOption = (index, text) => {
    const arr = options.slice();
    arr[index] = text;
    setOptions(arr);
  };

  const deleteOption = async (index) => {
    const arr = options.slice();
    arr.splice(index, 1);
    setOptions(arr);
  };

  const renderOption = () =>
    options.map((opt, index) => {
      return (
        <DropdownOption
          key={opt.id}
          updateOption={updateOption}
          deleteOption={deleteOption}
          index={index}
          opt={opt}
        />
      );
    });

  return (
    <div className={css.container}>
      <div className={css.optionHeader}>
        <h3>Options</h3>

        <span onClick={addOption}>Add option</span>
      </div>
      {!isEmpty(errors) ? (
        <span className={css.error}>You need to create at least 1 option.</span>
      ) : null}
      <ul>{renderOption()}</ul>
    </div>
  );
}

Dropdown.propTypes = {};

Dropdown.defaultProps = {
  errors: {},
};

export default Dropdown;
