import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import spinner from './spinner.gif';

const Spinner = ({ className }) => {
  return (
    <img
      className={classnames('spinner', className)}
      src={spinner}
      alt='Loading...'
    />
  );
};

Spinner.propTypes = {
  className: PropTypes.string
};

Spinner.defaultProps = {
  className: ''
};

export default Spinner;
