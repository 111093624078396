import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "../../../Inputs/TextField";
import Select from "../../../Inputs/Select";
import { isEmpty } from "../../../../utils";

function Text({ grabQuestionData, data }) {
  const [questionData, setQuestionData] = useState({
    maxResponseLength: 100,
    validator: {
      label: "Alphanumeric and symbols",
      value: "Alphanumeric and symbols",
    },
  });

  const { maxResponseLength, validator } = questionData;

  const typeOptions = [
    "Any text",
    "Alphanumeric and symbols",
    "Letters and symbols",
    "Numeric",
    "Email",
    "Phone Number",
    "Canadian Postal",
  ];

  useEffect(() => {
    setQuestionData({
      maxResponseLength: data.maxResponseLength ? data.maxResponseLength : 100,
      validator: {
        label: data.validator ? data.validator : "Any text",
        value: data.validator ? data.validator : "Any text",
      },
    });
  }, [data.maxResponseLength, data.validator]);

  useEffect(() => {
    const data = {
      maxResponseLength,
      validator: validator.value === "Any text" ? null : validator.value,
    };
    grabQuestionData(data);
  }, [maxResponseLength, validator]);

  const handleSelect = (val) => {
    setQuestionData({ ...questionData, validator: val });
  };

  return (
    <div>
      <TextField
        label="Response Length"
        type="number"
        value={maxResponseLength}
        name="maxResponseLength"
        format="####"
        className="input-lg"
        onChange={(e) =>
          setQuestionData({
            ...questionData,
            maxResponseLength: e.target.value,
          })
        }
      />

      <Select
        name="validator"
        value={validator}
        options={typeOptions.map((el) => ({ label: el, value: el }))}
        label="Response Type"
        onChange={handleSelect}
        clearable={false}
      />
    </div>
  );
}

Text.propTypes = {};

export default Text;
